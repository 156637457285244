
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'leader',
    component: resolve => require(['@/views/leader/leader.vue'], resolve),
  },  
]

const router = new VueRouter({
  mode: 'history',
  base:'/clerk/',
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}



export default router
