<template>
  <div id="app">
   <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Source Han Sans CN;
}
ul{
  list-style: none;
  margin-bottom: 0 !important;
}

</style>
